import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'

const SiennicaMuzeumSzkolnePage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Pomnik w Lasominie" />
      <ReturnToPrevPage to={'/gminy/siennica'}>
        Materiały dotyczące gminy Siennica
      </ReturnToPrevPage>
      <Header1>Pomnik w Lasominie</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Pomnik znajduje się na południowym krańcu wsi. na rozwidleniu dróg
          prowadzących do Kośmin i Nowogrodu. Poświęcony jest pamięci rodaków
          poległych w obronie ojczyzny w 1920 r. Pomnik ufundowało w 1932 r.
          Koło Młodzieży Wiejskiej „Wici” w Lasominie działające wówczas w tejże
          miejscowości. Treść: Rodakom naszym poległym w obronie Ojczyzny w r.
          1820 kamień ten na pamiątkę położyło Koło Mł. Wiejskiej „ Wici”. Do
          pomnika dodana 11 listopada 2011 roku przez samorząd gminy Siennica
          tablica upamiętniająca mieszkańców którzy zginęli w wojennych latach
          20 i 40.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/siennica/pomnik-lasomin/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/siennica/pomnik-lasomin/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default SiennicaMuzeumSzkolnePage
